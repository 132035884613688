$primary: #003049;
$white: #fff;
$whiteTweak: rgb(253, 253, 253);

$text: #2f2f2f;
$heading: #141125;
$boxShadow: 0 10px 25px 0 rgb(0 0 0 / 12%);
$smallBoxShadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
$success: #17cb6e;
$error: #ff4545;

* {
  scroll-behavior: smooth !important;
}

body {
  font-family: "Open Sans", sans-serif;
  color: $text;
  background-color: $whiteTweak;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: -0.04em;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

h5 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $heading;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.02em;
}

p.small {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.3em;
}

a {
  color: $heading;
  text-decoration: none;
  font-size: 14px;
  line-height: 26px;
}

a.white {
  color: $white;
  font-weight: 400;
  text-decoration: none;
}

a.white:hover {
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}

li {
  color: $heading;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

blockquote {
  margin-bottom: 10px;
  padding: 24px 32px;
  border: 2px dashed $primary;
  background-color: $white;
  color: $primary;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

input {
  height: 36px;
  margin: 4px 0px;
  padding: 0px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #f6f6fb;
  border-radius: 10px;
  box-shadow: $smallBoxShadow;
  transition: border-color 350ms ease, color 350ms ease;
  color: #070721;
  font-size: 16px;
}

.button {
  background-color: $primary;
  color: $white;
  padding: 10px 24px;
  margin: 4px 0px;
  border-radius: 10px;
  transition: box-shadow 350ms ease, background-color 350ms ease,
    -webkit-transform 350ms ease;
  transition: transform 350ms ease, box-shadow 350ms ease,
    background-color 350ms ease;
  transition: transform 350ms ease, box-shadow 350ms ease,
    background-color 350ms ease, -webkit-transform 350ms ease;
}
.button.secondary {
  background-color: $whiteTweak;
  color: $text;
  border-width: 1px;
  border-color: $primary;
  border-style: solid;
}
.button:hover {
  box-shadow: $boxShadow;
  transform: scale(0.98);
}



@media print {
  #CookiebotWidget {
    display: none;
  }
  .widget-visible{
    display: none;
  }
}